import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import styles from "../../Navbar.module.css";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Redirige al login sin cerrar sesión (ya no hay auth)
    navigate("/login");
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navLeft}>
        <h1 className={styles.navTitle}>Bienvenido</h1>
      </div>
      <div className={styles.navRight}>
        <Button onClick={handleLogout} className={styles.logoutButton}>
          Cerrar Sesión
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
