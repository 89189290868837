import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";

const App = () => {
  return (
    <Routes>
      {/* Ruta de inicio de sesión */}
      <Route path="/login" element={<Login />} />

      {/* Dashboard accesible sin autenticación */}
      <Route path="/dashboard" element={<Dashboard />} />

      {/* Ruta por defecto: redirige al login */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
